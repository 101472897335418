import './App.css';
import Hospital from './components/Hospital.js';
import Login from "./components/Login.js"
import LoginDemo from './components/LoginDemo.js';
import People from './components/People.js';
import Register from "./components/Register"
import Settings from './components/Settings.js';
import SettingsForHospital from './components/SettingsForHospital.js';

function App() {
  return (
    <div className="App">
        {/* <Login /> */}
        {/* <Register /> */}
        {/* <Settings /> */}
        {/* <SettingsForHospital /> */}
        {/* <People /> */}
        {/* <Hospital /> */}
        <LoginDemo />
    </div>
  );
}

export default App;
