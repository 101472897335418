import { io } from 'socket.io-client';

let socket = null;

export const connectSocket = ()=>{

    const options = {
        'force new connection': true,
        reconnectionAttempt: 'infinity',
        timeout: 10000,
        transports: ['websocket'],
        withCredentials: true,  
        rejectUnauthorized: false,
    }

    if (!socket) {
        socket = io(process.env.REACT_APP_SOCKET_URI, options);
    }
    
    return socket;
    
}

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
    socket = null;
  }
};