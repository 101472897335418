import React, { useState, useEffect, useRef } from 'react';
import { connectSocket, disconnectSocket } from '../middleware/socketConnection';

const LoginDemo = () => {
    const [email, setEmail] = useState('');
    const [receiverEmail, setReceiverEmail] = useState('');
    const [callerEmail, setCallerEmail] = useState('');
    const [socketConnected, setSocketConnected] = useState(false);
    const [incomingCall, setIncomingCall] = useState(false);
    
    const [localStream, setLocalStream] = useState(null);
    const [remoteStream, setRemoteStream] = useState(null);
    const peerConnectionRef = useRef(null);
    const socketRef = useRef(null);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if(token)
            connectingToSocket();

        return () => {
            disconnectSocket();
        };
    }, []);

    useEffect(()=>{
        const token = localStorage.getItem('token');
        console.log("tk", token);
        if(socketConnected && socketRef.current && socketRef.current.connected){
            if(token)
                socketRef.current.emit("actionJoined", JSON.parse(token))
        }
    },[socketConnected])

    const connectingToSocket = async () => {
        socketRef.current = await connectSocket();

        socketRef.current.on('connect', () => {
            setSocketConnected(true);
            console.log('Socket Connected');
        });

        socketRef.current.on('connect_error', (err) => {
            alert('Something went wrong');
            console.log('Socket Connection Error', err);
        });

        socketRef.current.on('connect_failed', (err) => {
            alert('Something went wrong');
            console.log('Socket Connection Failed', err);
        });

        socketRef.current.on("receiveCall", (data) => {
            const { callerEmail, offer } = data;
            setCallerEmail(callerEmail);
            setIncomingCall({ offer });
        });

        socketRef.current.on("acceptCall", async (data) => {
            const { answer } = data;
            await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(answer));
        });

        socketRef.current.on("iceCandidate", async (data) => {
            const { candidate } = data;
            try {
                await peerConnectionRef.current.addIceCandidate(new RTCIceCandidate(candidate));
            } catch (error) {
                console.error("Error adding ICE candidate:", error);
            }
        });

        socketRef.current.on("callRejected", () => {
            alert("Call rejected by the receiver");
            closeConnection();
        });

        socketRef.current.on("callEnded", () => {
            console.log("call end");
            alert("Call ended");
            closeConnection();
        });
    };

    const initiatePeerConnection = async () => {
        const peerConnection = new RTCPeerConnection();
        peerConnectionRef.current = peerConnection;

        peerConnection.onicecandidate = handleICECandidateEvent;
        peerConnection.ontrack = handleTrackEvent;

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
        setLocalStream(stream);
        stream.getTracks().forEach((track) => peerConnection.addTrack(track, stream));
    };

    const handleICECandidateEvent = (event) => {
        if (event.candidate) {
            socketRef.current.emit("iceCandidate", {
                candidate: event.candidate,
                receiverEmail,
            });
        }
    };

    const handleTrackEvent = (event) => {
        setRemoteStream(event.streams[0]);
    };

    const callingAction = async () => {
        await initiatePeerConnection();
        const offer = await peerConnectionRef.current.createOffer();
        await peerConnectionRef.current.setLocalDescription(offer);
        socketRef.current.emit("initiateCall", { receiverEmail, offer });
    };

    const acceptCallAction = async () => {
        await initiatePeerConnection();
        const offer = incomingCall.offer;
        await peerConnectionRef.current.setRemoteDescription(new RTCSessionDescription(offer));
        const answer = await peerConnectionRef.current.createAnswer();
        await peerConnectionRef.current.setLocalDescription(answer);
        socketRef.current.emit("acceptCall", { callerEmail, answer });
    };

    const rejectCallAction = () => {
        socketRef.current.emit("rejectCall", { callerEmail });
        setIncomingCall(false);
    };

    const endCallAction = () => {
        if(callerEmail)
            socketRef.current.emit("endCall", {email: callerEmail});
        else if(receiverEmail)
            socketRef.current.emit("endCall", {email: receiverEmail});
        console.log("here end");
        closeConnection();
    };

    const closeConnection = () => {
        if (peerConnectionRef.current) {
            peerConnectionRef.current.close();
            peerConnectionRef.current = null;
        }
        setLocalStream(null);
        setRemoteStream(null);
        setCallerEmail("");
        setReceiverEmail("");
    };
    
    const handleInputChange = (e) => {
        if (e.target.name === 'email') setEmail(e.target.value);
        if (e.target.name === 'receiverEmail') setReceiverEmail(e.target.value);
    };

    const submitAction = async () => {
        const data = await fetch(`${process.env.REACT_APP_SOCKET_URI}/token`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email }), 
        });

        const res = await data.json();
        console.log("Res", res);

        if (!res.success) {
            return console.log("Token not received");            
        }

        const authtoken = res.token;
        console.log(authtoken);

        localStorage.setItem('token', JSON.stringify(authtoken));

        if (authtoken) {
            connectingToSocket();
        }
    };

    return (
        <div className="w-100per h-100vh flex justify-center align-center">
            <div className="w-500 h-200">
                {!socketConnected && !incomingCall && <div className="p-rel w-100per h-45">
                    <input
                        type="text"
                        name="email"
                        value={email}
                        onChange={handleInputChange}
                        className="p-l45 w-100per h-100per outline-none fs-small br-f1 br-5"
                        placeholder="Email-id"
                        required
                    />
                    <div className="transform-y-50per p-abs top-50per left-10 p-t8 p-b8 p-l8 p-r8 gray">
                        <i className="bx bxs-user"></i>
                    </div>
                </div>}
                {socketConnected && !incomingCall && <div className="p-rel w-100per h-45">
                    <input
                        type="text"
                        name="receiverEmail"
                        value={receiverEmail}
                        onChange={handleInputChange}
                        className="p-l45 w-100per h-100per outline-none fs-small br-f1 br-5"
                        placeholder="Email-id"
                        required
                    />
                    <div className="transform-y-50per p-abs top-50per left-10 p-t8 p-b8 p-l8 p-r8 gray">
                        <i className="bx bxs-user"></i>
                    </div>
                </div>}
                {!socketConnected && !incomingCall && <div className="mt-20">
                    <button
                        onClick={submitAction}
                        className="btn-login w-100per bg-blue light fs-medium fw-500 pointer br-5 br-none"
                    >
                        Login
                    </button>
                </div>}
                {socketConnected && !incomingCall && <div className="mt-20">
                    <button
                        onClick={callingAction}
                        className="btn-login w-100per bg-blue light fs-medium fw-500 pointer br-5 br-none"
                    >
                        Call
                    </button>
                </div>}

                {incomingCall && <div className='flex-c r-gap'>
                    <h2>Call Received from {callerEmail}</h2>
                        <div className='flex c-gap'> 
                        <button
                            onClick={acceptCallAction}
                            className="btn-login w-100per bg-blue light fs-medium fw-500 pointer br-5 br-none"
                        >
                            Accept
                        </button>
                        <button
                            onClick={rejectCallAction}
                            className="btn-login w-100per bg-blue light fs-medium fw-500 pointer br-5 br-none"
                        >
                            Reject
                        </button>
                    </div>
                </div>}

                {localStream && (
                    <audio ref={(audio) => {
                        if (audio) {
                            audio.srcObject = localStream;
                        }
                    }} autoPlay />
                )}
                {remoteStream && (
                    <audio ref={(audio) => {
                        if (audio) {
                            audio.srcObject = remoteStream;
                        }
                    }} autoPlay />
                )}

                {(localStream || remoteStream) && (
                    <button
                        onClick={endCallAction}
                        className="btn-login w-100per bg-blue light fs-medium fw-500 pointer br-5 br-none"
                    >
                        End Call
                    </button>
                )}
            </div>
        </div>
    );
};

export default LoginDemo;