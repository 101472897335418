import React, { useState } from 'react'

const Hospital = () => {
    const [callModal, setCallModal] = useState(true);
    const [receivedCallModal, setReceivedCallModal] = useState(false);

    const showCallModal = ()=>{
        setCallModal(false);
        setReceivedCallModal(false);
    }

    const showReceivedCallModal = ()=>{
        setReceivedCallModal(!receivedCallModal);
    }

    return (
        <div>
            <div className='w-100per h-100vh flex-c justify-center align-center p-t20'>
                <h3>Receive or Reject Calls here</h3>
                {callModal && <div className='overlay flex justify-center align-center'>
                    <div className='bg-light flex-c r-gap align-center w-300 h-fit br-10 p-t20 p-b20 p-l20 p-r20 box-sh5 p-fixed z-index-3'>
                        <img src='/hospital/calling.gif' alt="done-img" />
                        <div className='w-100per flex justify-sp align-center'>
                            <button className='w-100 h-40 flex justify-center align-center bg-green light br-10 br-none outline-none fs-22 pointer' onClick={showReceivedCallModal}>Receive</button>
                            <button className='w-100 h-40 flex justify-center align-center bg-red light br-10 br-none outline-none fs-22 pointer' onClick={showCallModal}>Reject</button>
                        </div>
                    </div>
                </div>}
                {receivedCallModal && <div className='overlay flex justify-center align-center'>
                    <div className='bg-light flex-c r-gap align-center w-300 h-fit br-10 p-t20 p-b20 p-l20 p-r20 box-sh5 p-fixed z-index-3'>
                        <img src='/hospital/sound.gif' alt="done-img" />
                        <div className='w-100per flex justify-center align-center'>
                            <button className='w-100 h-40 flex justify-center align-center bg-red light br-10 br-none outline-none fs-22 pointer' onClick={showCallModal}>End</button>
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
}

export default Hospital